"use client";

import Image from "next/image";
import kiwiTicketLogo from "public/assets/images/kiwiticket_logo.svg";

type Props = {
  size?: number;
};

const KiwiLoader = ({ size = 96 }: Props) => (
  <div data-testid="kiwiloader" className="kiwiloader">
    <Image
      alt="Spinning kiwifruit loading indicator"
      src={kiwiTicketLogo}
      width={size}
      height={size}
      className="animate-spin"
    />
  </div>
);

export default KiwiLoader;
